html.page-column-detail {
  
  main {
    margin-bottom: 60px;
  }
  .column-detail {
    margin-top: 60px;
    
    margin: 40px 5% 20px 5%;
    @media (--sm-viewport) {
      margin: 40px 5%;
    }
    @media (--md-viewport) {
      max-width: 90%;
      margin: 60px auto 40px auto;
    }
    @media (--lg-viewport) {
      max-width: 1080px;
    }
  }
  
  .column-detail {
    &__title {
      display: flex;
      background: #fff;
      position: relative;
      border-bottom: 2px solid #52aed9;
      
      
      @media (--xs-viewport) {
        display: block;
      }
    }
    
    &__date {
      width: 140px;
      background: #52aed9;
      color: #fff;
      position: relative;
      padding: 20px 10px;
      text-align: center;
      box-sizing: border-box;
      
      display: flex;
      align-items: center;
      justify-content: center;
      
      @media (--xs-viewport) {
        width: 44%;
        padding: 5px;
      }
      
      .item__icon {
        font-family: "Josefin Sans", sans-serif;
        font-size: 12px;
        display: inline-block;
        width: 40px;
        text-align: center;
        white-space: nowrap;
        margin: 0 2px;
        vertical-align: bottom;
        
        @media (--xs-viewport) {
          width: 35px;
          font-size: 10px;
        }
        
        &:after {
          content: "";
          display: block;
          background: url(/assets/images/top/column_icon_nami.png) no-repeat;
          background-size: 100%;
          width: 40px;
          height: 20px;
          margin-top: 2px;
          
          @media (--xs-viewport) {
            width: 35px;
          }
        }
      }

      .item__date {
        display: inline-block;
        margin: 0 2px;
        
        span {
          font-size: 24px;
          line-height: 1.2;
          font-family: "Delius Swash Caps", cursive;
          display: block;
          text-align: center;
          
          @media (--xs-viewport) {
            font-size: 14px;
          }
        }
      }
    }
    
    &__text {
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 28px;
      font-weight: bold;
      line-height: 1.3;
      padding: 10px 20px 10px 30px;
      
      span {
        
      }
      
      @media (--xs-viewport) {
        padding: 20px 0;
        font-size: 20px;
      }
    }
    
    &__sns {
      display: flex;
      align-items: center;
      
      @media (--xs-viewport) {
        position: absolute;
        top: 0;
        right: 0;
      }
      
      a {
        transition: opacity 0.3s;
        opacity: 1;
        display: block;
        
        @media (--md-viewport) {
          &:hover {
            opacity: 0.7;
          }
        }
        
        @media (--xs-viewport) {
          width: 25px;
          height: 25px;
          
          img {
            width: 100%;
          }
        }
      }
      
      
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }
      
      li {
        float: left;
        padding-right: 8px;
        padding-bottom: 4px;
      }
    }
    
    &__content {
      background: #eaf4f9;
      padding: 45px;
      
      @media (--xs-viewport) {
        padding: 25px 3%;
      }
    }
  }
  
  
  
  .back-btn {
    width: 30%;
    margin: 50px auto;
    
    @media (--xs-viewport) {
      width: 75%;
    }
  }
  
}
  