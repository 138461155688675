html.page-recruit-graduates-entry, 
html.page-recruit-career-entry {
  main {
    margin-bottom: 60px;
  }
  section {
    padding-bottom: 30px;
    @media (--sm-viewport) {
      padding-bottom: 60px;
    }
  }
  .recruit-entry {
    div.recruit__title {
      &.triangle {
        .triangle__title {
          span {
            @media (--xs-viewport) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .entry-section {
    margin: 40px 5% 20px 5%;
    @media (--sm-viewport) {
      margin: 40px 5%;
    }
    @media (--md-viewport) {
      max-width: 90%;
      margin: 60px auto 40px auto;
    }
    @media (--lg-viewport) {
      max-width: 1080px;
    }
    .entry__title {
      position: relative;
      h3 {
        margin: 20px 0 10px 0;
        padding-bottom: 8px;
        font-size: 28px;
        border-bottom: 3px solid #52aed9;
        @media (--sm-viewport) {
          font-size: 33px;
        }
        &:before {
          content: url(/assets/images/business/index_fig_01.png);
          position: relative;
          top: 3px;
        }
      }
    }
  }
  .required-text {
    color: #d22222;
  }
  .required {
    padding-left: 0.3em;
    color: #d22222;
    font-size: 14px;
  }
  
  .recruit-table {
    margin: 20px 5%;
    @media (--sm-viewport) {
      margin: 40px 5%;
    }
    @media (--md-viewport) {
      max-width: 90%;
      margin: 40px auto;
    }
    @media (--lg-viewport) {
      max-width: 1080px;
    }
    
    dl {
      background-color: #fff;
      border-bottom: 1px solid #d4d8dd;
      @media (--sm-viewport) {
        display: flex;
        flex-wrap: wrap;
      }
    }
    dt {
      box-sizing: border-box;
      border-top: 1px solid #d4d8dd;
      padding: 1em;
      color: #2788c4;
      font-weight: bold;
      background-color: #eaf4f9;
      @media (--sm-viewport) {
        width: 20%;
        vertical-align: top;
      }
/*      @media (--md-viewport) {
        width: 15%;
      }*/
      &.table-entry-career {
        border-top: 2px solid #b7bbc0;
        background-color: #cce8f6;
        & + dd {
          @media (--sm-viewport) {
            border-top: 2px solid #b7bbc0;
          }
        }
      }
    }
    dd {
      box-sizing: border-box;
      margin-left: 0;
      padding: 1em 0;
      @media (--sm-viewport) {
        width: 80%;
        vertical-align: top;
        padding: 1em;
        border-top: 1px solid #d4d8dd;
      }
/*      @media (--md-viewport) {
        width: 85%;
      }*/
    }
    input[type=text] {
      box-sizing: border-box;
      width: 100%;
      margin: 4px 5px 4px 0;
      padding: 3px;
      border: 1px solid #cccccc;
      background-color: #fff;
      display: inline-block;
      @media (--sm-viewport) {
        width: 98%;
        max-width: 100%;
      }
      &.middle-input {
        width: 98%;
        @media (--sm-viewport) {
          width: 15rem;
        }
      }
      &.short-input {
        width: 7rem;
        &-half {
          width: 4rem;
        }
      }
      &:focus {
        outline: none;
        border: 1px solid #69b3e1;
      }
    }
    select {
      margin: 4px auto;
      padding: 3px;
      border: 1px solid #cccccc;
      background-color: #fff;
      &:focus {
        outline: none;
        border: 1px solid #69b3e1;
      }
    }
    label {
      .short-input {
        max-width: 25%;
        &-half {
          max-width: 13%;
        }
      }
    }
    textarea {
      box-sizing: border-box;
      border: 1px solid #cccccc;
      background-color: #fff;
      width: 100%;
      padding: 3px;
      &:focus {
        outline: none;
        border: 1px solid #69b3e1;
      }
    }
    .recruit-btn {
      max-width: 350px;
      margin: 40px auto;
    }
  }
  
  
  .mw_wp_form_preview {
    .recruit-btn {
      max-width: 700px;
      display: flex;
      
      button {
        margin: 0 25px;
      }
    }
  }
}

