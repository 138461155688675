html.page-contact-faq {
  main {
    margin-bottom: 60px;
  }
  section {
    padding-bottom: 30px;
    @media (--sm-viewport) {
      padding-bottom: 60px;
    }
  }

  .faq-list {
    margin: 40px 5% 20px 5%;
    @media (--sm-viewport) {
      margin: 40px 5%;
    }
    @media (--md-viewport) {
      max-width: 90%;
      margin: 60px auto 40px auto;
    }
    @media (--lg-viewport) {
      max-width: 1080px;
    }
    dl {
      position: relative;
      margin: 0 0 2px 0;
      padding: 0;
    }
    dt {
      position: relative;
      color: #2788c4;
      font-size: 20px;
      font-weight: bold;
      margin: 0 0 3px 0;
      padding: 5px 46px 5px 54px;
      background-color: #eaf4f9;
      cursor: pointer;
      @media (--sm-viewport) {
        font-size: 24px;
      }
      .list-q {
        display: inline-block;
        width: 46px;
        padding: 5px 0;
        color: #fff;
        text-align: center;
        background-color: #52aed9;
        position: absolute;
        top: 0;
        left: 0;
      }
      &:after {
        position: absolute;
        top: 20px;
        right: 17px;
        color: #fff;
        content: "";
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        border-right-width: 0;
        border-bottom-width: 0;
        border-left: 2px solid #52aed9;
        border-top: 2px solid #52aed9;
      }
      &.close {
        &+dd {
          display: none;
        }
        &:after {
          top: 16px;
          border-left-width: 0;
          border-top-width: 0;
          border-right: 2px solid #52aed9;
          border-bottom: 2px solid #52aed9;
        }
      }
    }
    dd {
      position: relative;
      margin: 0;
      padding: 16px 20px 30px 53px;
      @media (--sm-viewport) {
        padding: 20px 20px 40px 53px;
      }
      .list-a {
        display: inline-block;
        width: 46px;
        padding: 5px 0;
        font-size: 20px;
        font-weight: bold;
        color: #2788c4;
        text-align: center;
        position: absolute;
        top: 5px;
        left: 3px;
        @media (--sm-viewport) {
          font-size: 24px;
        }
      }
      div {
        margin: 0;
        p {
          margin-top: 0;
        }
      }
    }
  }

}

@media (--xs-viewport) {
}
@media (--xssm-only-viewport) {
}
@media (--sm-viewport) {
}
@media (--sm-only-viewport) {
}
@media (--md-viewport) {
}
@media (--lg-viewport) {
}
