html.page-recruit-member {

  main {
    margin-bottom: 60px;
  }
  .member-list {
    max-width: 1080px;
    margin: 30px auto;
    padding: 0;
  }
  .member-list ul {
    margin: 0;
    padding: 0;
    @media (--xssm-only-viewport) {
      margin: 0 3%;
    }
  }
  .member-list li {
    padding: 25px 0;
  }
  .member-photo {
    margin: 0 auto;
    padding: 0;
    z-index: 10;
    text-align: center;
    @media (--sm-viewport) {
      position: relative;
      right: -10px;
    }
    @media (--md-viewport) {
      right: -20px;
    }
    img {
      max-width: 100%;
      @media (min-width: 1080px) {
        max-width: 540px;
      }
    }
  }
  .member-txt {
    margin: 0;
    @media (--sm-viewport) {
      position: relative;
      left: -10px;
      margin: 50px 0 0;
    }
    @media (--md-viewport) {
      left: -20px;
      margin: 50px 0 0;
    }
    padding: 0;
    background-color: #ebf1f4;
    z-index: 1;
    dt {
      margin: 0;
      padding: 15px 5% 15px 5%;
      border-bottom: 3px solid #52aed9;
      font-size: 28px;
      font-weight: bold;
      .member-year {
        color: #2788c4;
        @media screen and (max-width: 360px) {
          display: block;
        }
        &:before {
          content: url(/assets/images/recruit/member_fig_01.png);
          height: 100%;
          position: relative;
          bottom: -3px;
        }
      }
      .member-name {
        display: inline-block;
      }
      @media (--sm-viewport) {
        padding: 20px 20px 15px 45px;
      }
      @media (--md-viewport) {
        font-size: 33px;
        padding: 25px 30px 15px 80px;
      }
    }
    dd {
      margin: 0;
      padding: 15px 5% 15px 5%;
      line-height: 1.7;
      @media (--sm-viewport) {
        padding: 25px 20px 20px 45px;
      }
      @media (--md-viewport) {
        padding: 25px 30px 30px 80px;
      }
    }
  }
  .f-r {
    @media (--sm-viewport) {
      flex-direction: row-reverse;
    }
    position: relative;
    .member-photo {
      @media (--sm-viewport) {
        position: relative;
        left: -10px;
      }
      @media (--md-viewport) {
        left: -20px;
      }
    }
    .member-txt {
      @media (--sm-viewport) {
        position: relative;
        right: -10px;
        left: auto;
      }
      @media (--md-viewport) {
        right: -20px;
      }
      dt {
        @media (--sm-viewport) {
           padding: 20px 40px 15px 20px;
        }
        @media (--md-viewport) {
           padding: 30px 60px 15px 30px;
        }
      }
      dd {
        @media (--sm-viewport) {
          padding: 25px 40px 20px 20px;
        }
        @media (--md-viewport) {
          padding: 25px 60px 30px 30px;
        }
      }
    }
  }

}

@media (--xs-viewport) {
}
@media (--xssm-only-viewport) {
}
@media (--sm-viewport) {
}
@media (--sm-only-viewport) {
}
@media (--md-viewport) {
}
@media (--lg-viewport) {
}
