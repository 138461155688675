html.page-business-results {
  main {
    margin-bottom: 60px;
  }
  section {
    padding-bottom: 30px;
    @media (--sm-viewport) {
      padding-bottom: 60px;
    }
  }

  .results-list {
    margin: 40px 5% 20px 5%;
    @media (--sm-viewport) {
      margin: 40px 5%;
    }
    @media (--md-viewport) {
      max-width: 90%;
      margin: 60px auto 40px auto;
    }
    @media (--lg-viewport) {
      max-width: 1080px;
    }
    dl {
      position: relative;
      margin: 0 0 2px 0;
      padding: 0;
    }
    dt {
      position: relative;
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      margin: 0;
      padding: 5px 50px 5px 8px;
      background-color: #52aed9;
      border-left: 3px solid #1a77b9;
      cursor: pointer;
      
      @media (--xs-viewport) {
        font-size: 16px;
        min-height: 46px;
        box-sizing: border-box;
        padding: 11px 50px 11px 8px;
      }
      
      .list-date {
        display: inline-block;
        margin: 5px 8px;
        font-size: 16px;
        font-weight: normal;
        @media (--sm-viewport) {
          position: absolute;
          right: 46px;
          margin: 8px;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #1a77b9;
        color: #fff;
        content: "";
        width: 46px;
        height: 46px;
      }
      &:after {
        position: absolute;
        top: 20px;
        right: 17px;
        color: #fff;
        content: "";
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        border-right-width: 0;
        border-bottom-width: 0;
        border-left: 2px solid #fff;
        border-top: 2px solid #fff;
      }
      &.close {
        &+dd {
          display: none;
        }
        &:after {
          top: 16px;
          border-left-width: 0;
          border-top-width: 0;
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
        }
      }
    }
    dd {
      margin: 0;
      padding: 20px;
      background-color: #eaf4f9;
      p {
        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

}

@media (--xs-viewport) {
}
@media (--xssm-only-viewport) {
}
@media (--sm-viewport) {
}
@media (--sm-only-viewport) {
}
@media (--md-viewport) {
}
@media (--lg-viewport) {
}
