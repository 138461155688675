html.page-recruit-workplace {

  main {
    margin-bottom: 60px;
  }
  .workplace-list {
    max-width: 1080px;
    margin: 30px auto;
    padding: 0;
    counter-reset: workplace;
  }
  .workplace-list ul {
    margin: 0;
    padding: 0;
    @media (--xssm-only-viewport) {
      margin: 0 3%;
    }
  }
  .workplace-list li {
    padding: 25px 0;
  }
  .workplace-photo {
    margin: 0 auto;
    padding: 0;
    z-index: 1;
    text-align: center;
    @media (--sm-viewport) {
      position: relative;
      right: -10px;
    }
    @media (--md-viewport) {
      right: -20px;
    }
    img {
      max-width: 100%;
      @media (min-width: 1080px) {
        max-width: 540px;
      }
    }
  }
  .workplace-txt {
    position: relative;
    margin: 0;
    padding: 0;
    z-index: 10;
    background-color: #ebf1f4;
    border: 1px solid  #ebf1f4;
    counter-increment: workplace;
    @media (--sm-viewport) {
      position: relative;
      left: -10px;
      margin: 50px 0 0 0;
    }
    @media (--md-viewport) {
      left: -20px;
      margin: 50px 0 0 0;
    }
    &:after {
      position: absolute;
      bottom: -75px;
      right: 5%;
      content: counter(workplace);
      font-size: 220px;
      font-weight: normal;
      color: #d5e6f2;
      z-index: 12;
      @media (--sm-viewport) {
        bottom: -95px;
      }
      @media (--md-viewport) {
        bottom: -95px;
      }
    }
    dt {
      position: relative;
      margin: 0;
      padding: 25px 5% 0 5%;
      font-size: 28px;
      font-weight: bold;
      color: #2788c4;
      text-align: center;
      z-index: 15;
      @media (--sm-viewport) {
        padding: 30px 20px 0 20px;
      }
      @media (--md-viewport) {
        font-size: 33px;
        padding: 35px 30px 0 30px;
      }
    }
    dd {
      position: relative;
      margin: 0;
      padding: 15px 5% 15px 5%;
      line-height: 1.7;
      z-index: 15;
      @media (--sm-viewport) {
        padding: 25px 20px 30px 20px;
      }
      @media (--md-viewport) {
        padding: 25px 30px 35px 30px;
      }
    }
  }
  .f-r {
    @media (--sm-viewport) {
      flex-direction: row-reverse;
    }
    position: relative;
    .workplace-photo {
      @media (--sm-viewport) {
        position: relative;
        left: -10px;
      }
      @media (--md-viewport) {
        left: -20px;
      }
    }
    .workplace-txt {
      @media (--sm-viewport) {
        position: relative;
        right: -10px;
        left: auto;
      }
      @media (--md-viewport) {
        right: -20px;
      }
/*      dt {
        @media (--sm-viewport) {
           padding: 20px 20px 0 20px;
        }
        @media (--md-viewport) {
           padding: 30px 30px 0 30px;
        }
      }
      dd {
        @media (--sm-viewport) {
          padding: 25px 20px 20px 20px;
        }
        @media (--md-viewport) {
          padding: 25px 30px 30px 30px;
        }
      }*/
    }
  }

}

@media (--xs-viewport) {
}
@media (--xssm-only-viewport) {
}
@media (--sm-viewport) {
}
@media (--sm-only-viewport) {
}
@media (--md-viewport) {
}
@media (--lg-viewport) {
}
