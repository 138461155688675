/* 2nd */
@import "./_contact-index.pcss";
/* 3rd */
@import "./_contact-faq.pcss";
@import "./_contact-contactus.pcss";

div.contact-main-title {
  background: #e8edf0 url(/assets/images/contact/main_ph.jpg) no-repeat center center;
  background-size: cover;
  h1.main-title__copy {
    margin: 0 auto;
    padding: 2rem 0;
    background: transparent url(/assets/images/pages/main_overlay.png) repeat left top;
    color: #fff;
    text-align: center;
    font-size: 35px;
    span {
      display: block;
      font-size: 18px;
      @media (--sm-viewport) {
        font-size: 23px;
      }
    }
    @media (--sm-viewport) {
      font-size: 53px;
    }
    @media (--md-viewport) {
      padding: 130px 0;
      line-height: 1.3;
      font-size: 60px;
    }
  }
  @media only screen and (min-width: 1800px) {
    background-size: contain;
  }
}

div.contact__title {
  &.triangle {
    position: relative;
    height: 130px;
    
    @media (--sm-viewport) {
      height: 170px;
    }
    @media (--md-viewport) {
      height: 190px;
    }
    
    .triangle__title {
      font-size: 33px;
      font-family: var(--font-nimbus);
      // letter-spacing: 0.06em;
      color: #fff;
      text-align: center;
      padding-top: 20px;
      margin: 0;
      position: relative;
      z-index: 1;
      line-height: 1;
      
      @media (--sm-viewport) {
        font-size: 40px;
        padding-top: 40px;
      }
      
      span {
        display: block;
        margin-top: 5px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.2;
        
        @media (--sm-viewport) {
          margin-top: 10px;
        }
      }
    }
    
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 130px 135px 0;
      border-color: #52aed9 transparent transparent transparent;
      @media (--sm-viewport) {
        border-width: 170px 200px 0 200px;
      }
      @media (--md-viewport) {
        border-width: 190px 210px 0 210px;
      }
    }
  }
}

