html.page-business-index {
  main {
    margin-bottom: 60px;
  }
  .row {
    position: relative;
    z-index: 1;
    
    @media (--sm-viewport) {
      padding: 0;
    }
  }
  .business-title {
    color: #fff;

    .en {
      &:before {
        background: url(/assets/images/business/index_fig_01.png);
      }
    }
  }
  .business-lead {
    font-size: 23px;
    font-weight: bold;
    margin: 15px 0;

    @media (--sm-viewport) {
      font-size: 28px;
    }
    @media (--md-viewport) {
      font-size: 33px;
      max-width: 82.5%;
    }
  }
  .business-text {
    margin: 15px 0 25px 0;
    @media (--md-viewport) {
      max-width: 75%;
    }
  }

  .business-img {
    position: absolute;
    margin: 0;
    padding: 0;
    height: 100%;
    
    img {
      height: 100%;
    }
    
    &.img-right {
      left: auto;
      right: 0;
      text-align: right;
      
      @media (--sm-only-viewport) {
        right: -20%;
      }
      
      @media (--xs-lg-viewport) {
        left: 45%;
        right: auto;
      }
    }

    &.img-left {
      right: auto;
      left: 0;
      text-align: left;
      

      @media (--sm-viewport) {
        left: auto;
        right: 50%;
        text-align: right;
      }
      @media (--lg-viewport) {
        left: -80px;
        right: auto;
        text-align: left;
      }

      
    }
    
    @media (--xs-viewport) {
      display: none;
    }
  }
  
  .business-spimg {
    @media (--sm-viewport) {
      display: none;
    }
    
    img {
      height: auto;
      width: 100%;
    }
  }

  .business-btn {
    @media (--sm-viewport) {
      max-width: 300px;
      padding-bottom: 15px;
    }
    .un-button.un-button-blue,
    .un-button.un-button-orange,
    .un-button.un-button-green {
      border-color: #fff;
    }
    .un-button.un-button-blue {
      background-color: #22acfb;
      color: #fff;
    }
    .un-button.un-button-blue:before {
      background-color: #24beff;
      color: #fff;
    }
    .un-button.un-button-orange {
      background-color: #f1a015;
      color: #fff;
    }
    .un-button.un-button-orange:before {
      background-color: #fdb512;
      color: #fff;
    }
    .un-button.un-button-green {
      background-color: #75d956;
      color: #fff;
    }
    .un-button.un-button-green:before {
      background-color: #85e760;
      color: #fff;
    }
    a {
      span {
        font-size: 20px;
      }
    }
  }





  .business-works {
    background: linear-gradient(90deg, #22acfb 80%, transparent 0);
    overflow: hidden;
    position: relative;
    
    @media (--xs-viewport) {
      background: linear-gradient(135deg, #22acfb 40%, transparent 0)
    }
    
    .row {
      
      @media (--md-viewport) {
        margin: 0;
        padding: 0;
      }
/*      @media (--lg-viewport) {
        margin: 0 0 0 auto;
        padding: 0;
      }
      @media (min-width: 1200px) {
        margin: 0 auto;
        padding: 0;
        max-width: 1200px;
      }*/
      > .col-xs-12 {
        padding: 0;

        @media (--lg-viewport) {
          max-width: 600px;
          margin: 0 0 0 auto;
          padding: 0;
        }
        &+ .col-sm-6 {
          margin: 0;
          padding-right: 0;
          @media (min-width: 1200px) {
/*            max-width: 100%;*/
          }
        }
      }
    }
    .business-title {
      @media (--sm-only-viewport) {
        padding-left: 5%;
      }
    }
    .business-lead {
      color: #00204e;
    }
    
    .business-img {
      &.img-right {
      }
    }


  }






  .business-qualifications {
    background: linear-gradient(90deg, #f1a015 100%, transparent 0);
    overflow: hidden;
    position: relative;
    
    @media (--xs-viewport) {
      background: linear-gradient(135deg, #f1a015 40%, transparent 0)
    }
    
    .row {
      
      @media (--md-viewport) {
        margin: 0;
        padding: 0;
      }
/*      @media (--lg-viewport) {
        margin: 0 0 0 auto;
        padding: 0;
      }
      @media (min-width: 1200px) {
        margin: 0 auto;
        padding: 0;
        max-width: 1200px;
      }*/
      > .col-xs-12 {
        padding: 0;

        @media (--lg-viewport) {
          max-width: 600px;
          margin: 0 0 0 auto;
          padding: 0;
        }
        &+ .col-sm-6 {
          margin: 0;
          padding-right: 0;
          @media (min-width: 1200px) {
/*            max-width: 100%;*/
          }
        }
      }
    }
    .business-title {
      @media (--sm-only-viewport) {
        padding-left: 5%;
      }
    }
    .business-lead {
      color: #00204e;
    }
    
    .business-img {
      &.img-left {
      }
    }


  }












  .business-results {
    background: linear-gradient(90deg, #75d956 80%, transparent 0);
    overflow: hidden;
    position: relative;
    @media (--xs-viewport) {
      background: linear-gradient(135deg, #75d956 40%, transparent 0)
    }
    
    .row {
      @media (--md-viewport) {
        margin: 0;
        padding: 0;
      }
/*      @media (--lg-viewport) {
        margin: 0 0 0 auto;
        padding: 0;
      }
      @media (min-width: 1200px) {
        margin: 0 auto;
        padding: 0;
        max-width: 1200px;
      }*/
      > .col-xs-12 {
        padding: 0;

        @media (--lg-viewport) {
          max-width: 600px;
          margin: 0 0 0 auto;
          padding: 0;
        }
        &+ .col-sm-6 {
          margin: 0;
          padding-right: 0;
          @media (min-width: 1200px) {
/*            max-width: 100%;*/
          }
        }
      }
    }
    .business-title {
      @media (--sm-only-viewport) {
        padding-left: 5%;
      }
    }
    .business-lead {
      color: #00403f;
    }
    .business-img {
      &.img-right {
      }
    }
  }


}
