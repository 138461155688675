/* 2nd */
/*@import "./_privacy-index.pcss";*/
/* 3rd */


div.privacy-main-title,
div.personalinfo-main-title,
div.rules-main-title {
  background: #e8edf0 url(/assets/images/privacy/main_ph.jpg) no-repeat center center;
  background-size: cover;
  h1.main-title__copy {
    margin: 0 auto;
    padding: 2rem 0;
    background: transparent url(/assets/images/pages/main_overlay.png) repeat left top;
    color: #fff;
    text-align: center;
    font-size: 35px;
    span {
      display: block;
      font-size: 18px;
      @media (--sm-viewport) {
        font-size: 23px;
      }
    }
    @media (--sm-viewport) {
      font-size: 53px;
    }
    @media (--md-viewport) {
      padding: 130px 0;
      line-height: 1.3;
      font-size: 60px;
    }
  }
  @media only screen and (min-width: 1800px) {
    background-size: contain;
  }
}

.page-privacy,
.page-personalinfo,
.page-rules {
  main {
    margin-bottom: 60px;
  }
  section {
    padding: 30px 0;
    @media (--sm-viewport) {
      padding-bottom: 60px;
    }
  }
  .section {
    margin: 40px 5% 20px 5%;
    
    @media (--sm-viewport) {
      margin: 40px 5%;
    }
    @media (--md-viewport) {
      max-width: 90%;
      margin: 60px auto;
    }
    @media (--lg-viewport) {
      max-width: 1080px;
    }
    
    .title {
      margin: 1em 0;
      font-size: 24px;
      font-weight: bold;
    }
    
    .text {
      margin: 1em 0;
      font-size: 16px;
      line-height: 1.7;
    }
    
    @media (--xs-viewport) {
      .title {
        font-size: 20px;
      }
      
      .text {
        font-size: 14px;
      }
      
    }
    
    .container {
      margin-bottom: 45px;
    }
  }
}
