html.page-business-works {
  main {
    margin-bottom: 60px;
  }
  section {
    padding-bottom: 30px;
    @media (--sm-viewport) {
      padding-bottom: 60px;
    }
  }
  .works-section {
    margin: 40px 5% 20px 5%;
    @media (--sm-viewport) {
      margin: 40px 5%;
    }
    @media (--md-viewport) {
      max-width: 90%;
      margin: 60px auto 40px auto;
    }
    @media (--lg-viewport) {
      max-width: 1080px;
    }
    p {
      margin: 25px 0;
    }
    .row.f-r {
      flex-direction: row-reverse;
      .col-sm-6 {
        p {
          @media (--sm-viewport) {
            padding-left: 20px;
            padding-right: 0;
          }
        }
      }
    }
    .col-sm-6 {
      p {
        margin: 20px 0;
        @media (--sm-viewport) {
          padding-right: 20px;
        }
      }
      img {
        @media (--sm-viewport) {
          margin-top: 20px;
        }
      }
    }
    h3 {
      margin: 20px 0 15px 0;
      padding-bottom: 8px;
      font-size: 28px;
      border-bottom: 3px solid #52aed9;
      @media (--sm-viewport) {
        font-size: 33px;
      }
      &:before {
        content: url(/assets/images/business/index_fig_01.png);
        position: relative;
        top: 3px;
      }
    }
    img {
      width: 100%;
      @media (--sm-viewport) {
        max-width: 520px;
      }
    }
  }

}

@media (--xs-viewport) {
}
@media (--xssm-only-viewport) {
}
@media (--sm-viewport) {
}
@media (--sm-only-viewport) {
}
@media (--md-viewport) {
}
@media (--lg-viewport) {
}
