html.page-company-greeting {

  .company {
    &__outer {
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(244,248,249,1) 65%,rgba(235,241,244,1) 100%); 
      margin-bottom: 60px;
    }
    
    &__inner {
      padding-bottom: 30px;
      @media (--sm-viewport) {
        padding-bottom: 60px;
      }
    }
  }

  .company-subtitle {
    @media (--sm-viewport) {
      float: left;
    }
    h3 {
      font-size: 26px;
      @media (--xs-viewport) {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      @media (--sm-viewport) {
        font-size: 33px;
      }
    }
  }
  .company-img {
    text-align: center;
    @media (--sm-only-viewport) {
      max-width: 50%;
    }
    @media (--sm-viewport) {
      float: right;
      vertical-align: top;
    }
    p {
      @media (--xs-viewport) {
        margin-top: 0;
        padding-top: 0;
      }
    }
    img {
      max-width: 90%;
      @media (--sm-only-viewport) {
        max-width: 90%;
      }
      @media (--md-viewport) {
        max-width: auto;
      }
    }
  }
  .company-text {
    line-height: 1.7;
    > p:first-child {
      @media (--sm-viewport) {
        clear: left;
      }
    }
    > p:last-child {
      padding-top: 2em;
    }
    .company-signiture {
      display: block;
      padding-top: 0.8em;
    }
  }


}
