html.page-column-index {

  main {
    margin-bottom: 60px;
  }
  .column-list {
    margin-top: 60px;
    
    margin: 40px 5% 20px 5%;
    @media (--sm-viewport) {
      margin: 40px 5%;
    }
    @media (--md-viewport) {
      max-width: 90%;
      margin: 60px auto 40px auto;
    }
    @media (--lg-viewport) {
      max-width: 1080px;
    }
  }
  
  .column-items {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .column-item {
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
    
    @media (--xs-viewport) {
      margin-bottom: 10px;
    }
    
    a {
      text-decoration: none;
      display: flex;
      background: #edeff2;
      position: relative;
      transition: background 0.3s;
      
      @media (--sm-viewport) {
        &:hover {
          background: #e6f4fb;
        }
      }
      
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 5%;
        background: url(/assets/images/column/column_arr.png) no-repeat;
        background-size: 100%;
        width: 14px;
        height: 27px;
        margin-top: -13px;
        
        @media (--xs-viewport) {
          width: 7px;
          height: 13px;
          margin-top: -6px;
        }
      }
      
      .column-item__date {
        width: 140px;
        background: #52aed9;
        color: #fff;
        position: relative;
        padding: 20px 10px;
        text-align: center;
        box-sizing: border-box;
        
        display: flex;
        align-items: center;
        justify-content: center;
        
        .column-item__date-inner {
          
        }
        
        @media (--xs-viewport) {
          width: 30%;
          padding: 10px;
        }
        
        
        .item__icon {
          font-family: "Josefin Sans", sans-serif;
          font-size: 12px;
          display: inline-block;
          width: 40px;
          text-align: center;
          white-space: nowrap;
          margin: 0 2px;
          vertical-align: bottom;
          
          &:after {
            content: "";
            display: block;
            background: url(/assets/images/top/column_icon_nami.png) no-repeat;
            background-size: 100%;
            width: 40px;
            height: 20px;
            margin-top: 4px;
          }
          
          @media (--xs-viewport) {
            font-size: 10px;
            width: 35px;
            
            &:after {
              width: 35px;
              height: 18px;
            }
          }
        }
  
        .item__date {
          display: inline-block;
          margin: 0 2px;
          
          span {
            font-size: 24px;
            line-height: 1.2;
            font-family: "Delius Swash Caps", cursive;
            display: block;
            text-align: center;
            
            @media (--xs-viewport) {
              font-size: 13px;
            }
          }
        }
      }
      
      .column-item__text {
        display: flex;
        align-items: center;
        flex: 1;
        font-size: 28px;
        font-weight: bold;
        line-height: 1.3;
        color: #2788c4;
        padding: 10px 8% 10px 30px;
        
        span {
          
        }
        
        @media (--xs-viewport) {
          padding: 10px 8% 10px 5%;
          font-size: 16px;
        }
      }
    }
  }
  
}
  