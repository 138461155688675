/* 2nd */
@import "./_column-index.pcss";
/* 3rd */
@import "./_column-detail.pcss";

.column-main-title {
  background: #e8edf0 url(/assets/images/column/main_ph.jpg) no-repeat center center;
  background-size: cover;
  h1.main-title__copy {
    margin: 0 auto;
    padding: 2rem 0;
    background: transparent url(/assets/images/pages/main_overlay.png) repeat left top;
    color: #fff;
    text-align: center;
    font-size: 35px;
    span {
      display: block;
      font-size: 18px;
      @media (--sm-viewport) {
        font-size: 23px;
      }
    }
    @media (--sm-viewport) {
      font-size: 53px;
    }
    @media (--md-viewport) {
      padding: 130px 0;
      line-height: 1.3;
      font-size: 60px;
    }
  }
  @media only screen and (min-width: 1800px) {
    background-size: contain;
  }
}