html.page-contact-contactus {
  main {
    margin-bottom: 60px;
  }
  section {
    padding-bottom: 30px;
    @media (--sm-viewport) {
      padding-bottom: 60px;
    }
  }
  
  .contact-table {
    margin: 40px 5% 20px 5%;
    @media (--sm-viewport) {
      margin: 40px 5%;
    }
    @media (--md-viewport) {
      max-width: 90%;
      margin: 60px auto 40px auto;
    }
    @media (--lg-viewport) {
      max-width: 1080px;
    }
    dl {
      background-color: #fff;
      border-bottom: 1px solid #d4d8dd;
      @media (--sm-viewport) {
        display: flex;
        flex-wrap: wrap;
      }
    }
    dt {
      box-sizing: border-box;
      border-top: 1px solid #d4d8dd;
      padding: 1em;
      color: #2788c4;
      font-weight: bold;
      background-color: #eaf4f9;
      @media (--sm-viewport) {
        width: 20%;
        vertical-align: top;
      }
      @media (--md-viewport) {
        width: 20%;
      }
    }
    dd {
      box-sizing: border-box;
      margin-left: 0;
      padding: 1em;
      
      textarea {
        width: 100%;
      }
      @media (--xs-viewport) {
        input[type="text"] {
          width: 100%;
        }
      }
      
      @media (--sm-viewport) {
        width: 80%;
        vertical-align: top;
        padding: 1em;
        border-top: 1px solid #d4d8dd;
      }
      @media (--md-viewport) {
        width: 80%;
      }
    }
    .contact-btn {
      max-width: 350px;
      margin: 40px auto;
    }
  }

  .mw_wp_form_preview {
    .contact-btn {
      max-width: 700px;
      display: flex;
      
      button {
        margin: 0 25px;
      }
    }
  }
}
