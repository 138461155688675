html.page-company-summary {
  main {
    margin-bottom: 60px;
  }
  section {
    padding-bottom: 30px;
    @media (--sm-viewport) {
      padding-bottom: 60px;
    }
  }
  .summary-table {
    margin: 40px 5% 20px 5%;
    @media (--sm-viewport) {
      margin: 40px 5%;
    }
    @media (--md-viewport) {
      max-width: 90%;
      margin: 60px auto 40px auto;
    }
    @media (--lg-viewport) {
      max-width: 1080px;
    }
    dl {
      background-color: #fff;
      border-bottom: 1px solid #d4d8dd;
      @media (--sm-viewport) {
        display: flex;
        flex-wrap: wrap;
      }
    }
    dt {
      box-sizing: border-box;
      border-top: 1px solid #d4d8dd;
      padding: 1em;
      color: #2788c4;
      font-weight: bold;
      @media (--sm-viewport) {
        width: 20%;
        vertical-align: top;
      }
      @media (--md-viewport) {
        width: 15%;
      }
    }
    dd {
      box-sizing: border-box;
      margin-left: 0;
      padding: 1em;
      @media (--sm-viewport) {
        width: 80%;
        vertical-align: top;
        padding: 1em;
        border-top: 1px solid #d4d8dd;
      }
      @media (--md-viewport) {
        width: 85%;
      }
    }
  }

  .company-summary {
    .summary-table {
      dt {
        background-color: #eaf4f9;
      }
    }
  }
  .company-history {
    background-color: #eaf4f9;
    div.company__title {
      &.triangle {
        .triangle__title {
          color: #2788c4;
        }
        
        &:before {
          border-color: #fff transparent transparent transparent;
        }
      }
    }
    .summary-table {
      dd {
        padding-top: 0;
        @media (--sm-viewport) {
          padding: 1em;
        }
      }
    }
  }

}

@media (--xs-viewport) {
}
@media (--xssm-only-viewport) {
}
@media (--sm-viewport) {
}
@media (--sm-only-viewport) {
}
@media (--md-viewport) {
}
@media (--lg-viewport) {
}
