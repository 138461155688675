html.page-contact-index {

  main {
    margin-bottom: 60px;
  }
  .row {
    position: relative;
    z-index: 1;
    
    @media (--sm-viewport) {
      padding: 0;
    }
  }
  .contact-title {

  }
  .contact-lead {
    font-size: 23px;
    font-weight: bold;
    margin: 15px 0;

    @media (--sm-viewport) {
      font-size: 28px;
      margin-bottom: 25px;
    }
    @media (--md-viewport) {
      font-size: 33px;
      margin-bottom: 35px;
    }
  }
  .contact-text {
    color: #2788c4;
    margin: 15px 0 25px 0;

  }
  .contact-spimg {
    @media (--sm-viewport) {
      display: none;
    }
    
    img {
      height: auto;
      width: 100%;
    }
  }

  .contact-btn {

    @media (--sm-viewport) {
      max-width: 300px;
      padding-bottom: 40px;
    }
    @media (--md-viewport) {
      margin-bottom: 55px;
    }
    a {
      span {
        font-size: 20px;
      }
    }
  }
  
  .contact-img {
    position: absolute;
    margin: 0;
    padding: 0;
    
    img {
      height: 100%;
    }
  }

  .contact-contactus {
    position: relative;
    overflow: hidden;
    
    .contact-img {
      top: 0;
      right: 0;
      height: 100%;
      text-align: right;
      @media (--sm-only-viewport) {
        right: -40%;
      }
      @media (--xs-viewport) {
        display: none;
      }
    }
    
    .row {
      @media (--md-viewport) {
        margin: 0;
        padding: 0;
      }
/*      @media (--lg-viewport) {
        margin: 0 0 0 auto;
        padding: 0;
      }
      @media (min-width: 1200px) {
        margin: 0 auto;
        padding: 0;
        max-width: 1200px;
      }*/
      > .col-xs-12 {
        padding: 0;

        @media (--lg-viewport) {
          max-width: 600px;
          margin: 0 0 0 auto;
          padding: 0;
        }
        &+ .col-sm-6 {
          margin: 0;
          padding-right: 0;
          @media (min-width: 1200px) {
            max-width: 100%;
          }
        }
      }
    }
    .contact-title {

    }
  }
  .contact-faq {
    position: relative;
    background-color: #ebf5f9;
    overflow: hidden;
    
    .contact-img {
      top: 0;
      left: 0;
      height: 100%;
      @media (--sm-only-viewport) {
        left: -20%;
      }
      @media (--xs-viewport) {
        display: none;
      }
    }
    
    .row {
      > .col-sm-6 {
        padding-left: 0;
        &+ .col-sm-6 {
          @media (--lg-viewport) {
            max-width: 600px;
          }
        }
      }
    }
    .contact-title, 
    .contact-lead {
      @media (--sm-only-viewport) {
        text-shadow: 0 0 5px #fff;
      }
    }
    .contact-text {
      @media (--sm-only-viewport) {
        text-shadow: 1px 1px 2px #333, 2px 2px 5px #fff;
      }
    }
  }

}

@media (--xs-viewport) {
}
@media (--xssm-only-viewport) {
}
@media (--sm-viewport) {
}
@media (--sm-only-viewport) {
}
@media (--md-viewport) {
}
@media (--lg-viewport) {
}
