html.page-recruit-graduates, 
html.page-recruit-career {
  main {
    margin-bottom: 60px;
  }
  section {
    padding-bottom: 30px;
    @media (--sm-viewport) {
      padding-bottom: 60px;
    }
  }

  .recruit-requirements {
    div.recruit__title {
      &.triangle {
        .triangle__title {
          @media (--xs-viewport) {
            font-size: 28px;
          }
          @media (--sm-only-viewport) {
            font-size: 36px;
          }
        }
      }
    }
  }

  .requirements-section {
    margin: 40px 5% 20px 5%;
    @media (--sm-viewport) {
      margin: 40px 5%;
    }
    @media (--md-viewport) {
      max-width: 90%;
      margin: 60px auto 40px auto;
    }
    @media (--lg-viewport) {
      max-width: 1080px;
    }
    .requirements__title {
      position: relative;
      h3 {
        margin: 20px 0 15px 0;
        padding-bottom: 8px;
        font-size: 28px;
        border-bottom: 3px solid #52aed9;
        @media (--sm-viewport) {
          font-size: 33px;
        }
        &:before {
          content: url(/assets/images/business/index_fig_01.png);
          position: relative;
          top: 3px;
        }
        a {
          position: relative;
          display: block;
          @media (--sm-viewport) {
            display: inline-block;
          }
          margin-left: 45px;
          vertical-align: middle;
          font-size: 18px;
          font-weight: normal;
          color: #2788c4;
          text-decoration: none;
          &:before {
            position: absolute;
            top: 10px;
            left: -14px;
            content: "";
            width: 7px;
            height: 7px;
            transform: rotate(45deg);
            border-right: 1px solid #3a7395;
            border-top: 1px solid #3a7395;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .faq-btn {
        @media (--md-viewport) {
          position: absolute;
          top: -15px;
          right: 0;
        }
        a {
          position: relative;
          padding: 13px 10px 13px 10px;
          width: 270px;
          @media (--xs-viewport) {
            width: 100%;
          }
          &:after {
            position: absolute;
            top: 20px;
            right: 14px;
            content: "";
            width: 7px;
            height: 7px;
            transform: rotate(45deg);
            border-right: 1px solid #3a7395;
            border-top: 1px solid #3a7395;
            z-index: 2;
          }
          @media (--sm-viewport) {
            &.un-button:hover {
              &:after {
                border-right: 1px solid #fff;
                border-top: 1px solid #fff;
              }
            }
          }
        }
      }
    }
    .requirements-table {
      margin: 40px 0;
      @media (--md-viewport) {
        max-width: 90%;
        margin: 40px auto 60px auto;
      }
      @media (--lg-viewport) {
        max-width: 1080px;
      }
      dl {
        background-color: #fff;
        border-bottom: 1px solid #d4d8dd;
        @media (--sm-viewport) {
          display: flex;
          flex-wrap: wrap;
        }
      }
      dt {
        box-sizing: border-box;
        border-top: 1px solid #d4d8dd;
        background-color: #eaf4f9;
        padding: 1em;
        color: #2788c4;
        font-weight: bold;
        @media (--sm-viewport) {
          width: 25%;
          vertical-align: top;
        }
        @media (--md-viewport) {
          width: 20%;
        }
      }
      dd {
        box-sizing: border-box;
        margin-left: 0;
        padding: 1em;
        @media (--sm-viewport) {
          width: 75%;
          vertical-align: top;
          padding: 1em;
          border-top: 1px solid #d4d8dd;
        }
        @media (--md-viewport) {
          width: 80%;
        }
      }
    }
    .entry-btn {
      text-align: center;
      p {
        margin: 0 auto;
        &.recruit-btn {
          .un-button {
            width: 400px;
            max-width: 100%;
            font-size: 20px;
          }
        }
      }
    }
  }

}

@media (--xs-viewport) {
}
@media (--xssm-only-viewport) {
}
@media (--sm-viewport) {
}
@media (--sm-only-viewport) {
}
@media (--md-viewport) {
}
@media (--lg-viewport) {
}
