html.page-business-qualifications {
  main {
    margin-bottom: 60px;
  }
  section {
    padding-bottom: 30px;
    @media (--sm-viewport) {
      padding-bottom: 60px;
    }
  }

  .business-qualifications {
    div.business__title {
      &.triangle {
        .triangle__title {
          @media (--xs-viewport) {
            font-size: 28px;
          }
          @media (--sm-only-viewport) {
            font-size: 36px;
          }
        }
      }
    }
  }

  .qualifications-table {
    margin: 40px 5% 20px 5%;
    @media (--sm-viewport) {
      margin: 40px 5%;
    }
    @media (--md-viewport) {
      max-width: 90%;
      margin: 60px auto 40px auto;
    }
    @media (--lg-viewport) {
      max-width: 1080px;
    }
    dl {
      position: relative;
      margin: 0 0 70px 0;
      padding: 40px;
      background-color: #ebf1f4;
    }
    dt {
      display: inline-block;
      position: absolute;
      top: -26px;
      left: calc(50%-7rem);
      background: transparent url(/assets/images/business/qualifications_fig_01.png) no-repeat center center;
      background-size: cover;
      color: #fff;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin: 0 auto;
      padding: 8px 0;
      width: 14rem;
    }
    dd {
      margin: 0;
      padding: 20px;
      background-color: #fff;
      border-top: 1px solid #ebf1f4;
      border-bottom: 1px solid #ebf1f4;
    }
    dd:before {
      content: "■ ";
    }
  }

}

@media (--xs-viewport) {
}
@media (--xssm-only-viewport) {
}
@media (--sm-viewport) {
}
@media (--sm-only-viewport) {
}
@media (--md-viewport) {
}
@media (--lg-viewport) {
}
