html.page-recruit-message {

  main {
    margin-bottom: 60px;
  }
  .message-list {
    max-width: 1080px;
    margin: 30px auto;
    padding: 0;
  }
  .message-list ul {
    margin: 0;
    padding: 0;
    @media (--xssm-only-viewport) {
      margin: 0 3%;
    }
  }
  .message-list li {
    padding: 25px 0;
  }
  .message-photo {
    margin: 0 auto;
    padding: 0;
    z-index: 10;
    text-align: center;
    @media (--sm-viewport) {
      position: relative;
      right: -10px;
    }
    @media (--md-viewport) {
      right: -20px;
    }
    img {
      max-width: 100%;
      @media (min-width: 1080px) {
        max-width: 540px;
      }
    }
  }
  .message-txt {
    margin: 0;
    @media (--sm-viewport) {
      position: relative;
      left: -10px;
      margin: 50px 0 0;
    }
    @media (--md-viewport) {
      left: -20px;
      margin: 50px 0 0;
    }
    padding: 0;
    background-color: #eaf4f9;
    z-index: 1;
    dt {
      margin: 0;
      padding: 35px 5% 0 5%;
      font-size: 28px;
      font-weight: bold;
      color: #2788c4;
      @media (--sm-viewport) {
        padding: 40px 20px 0 45px;
      }
      @media (--md-viewport) {
        font-size: 33px;
        padding: 45px 30px 0 80px;
      }
    }
    dd {
      margin: 0;
      padding: 15px 5% 15px 5%;
      line-height: 1.7;
      @media (--sm-viewport) {
        padding: 25px 20px 20px 45px;
      }
      @media (--md-viewport) {
        padding: 25px 30px 30px 80px;
      }
    }
  }

}

@media (--xs-viewport) {
}
@media (--xssm-only-viewport) {
}
@media (--sm-viewport) {
}
@media (--sm-only-viewport) {
}
@media (--md-viewport) {
}
@media (--lg-viewport) {
}
