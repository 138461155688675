html.page-environment-index {

  main {
    margin-bottom: 60px;
  }
  .row {
    position: relative;
    z-index: 1;
    
    @media (--sm-viewport) {
      padding: 0;
    }
  }
  .environment-title {

  }
  .environment-lead {
    font-size: 23px;
    font-weight: bold;
    margin: 15px 0;

    @media (--sm-viewport) {
      font-size: 28px;
    }
    @media (--md-viewport) {
      font-size: 33px;
    }
  }
  .environment-text {
    color: #2788c4;
    margin: 15px 0 25px 0;

  }
  .environment-spimg {
    @media (--sm-viewport) {
      display: none;
    }
    
    img {
      height: auto;
      width: 100%;
    }
  }

  .environment-btn {

    @media (--sm-viewport) {
      max-width: 300px;
      padding-bottom: 20px;
    }
    a {
      span {
        font-size: 20px;
      }
    }
  }
  
  .environment-img {
    position: absolute;
    margin: 0;
    padding: 0;
    
    img {
      height: 100%;
    }
  }

  .environment-renewable {
    position: relative;
    overflow: hidden;
    
    .environment-img {
      top: 0;
      right: 0;
      height: 100%;
      text-align: right;
      
      @media (--sm-only-viewport) {
        right: -40%;
      }
      @media (--xs-viewport) {
        display: none;
      }
    }
    
    .row {
      @media (--md-viewport) {
        margin: 0;
        padding: 0;
      }
/*      @media (--lg-viewport) {
        margin: 0 0 0 auto;
        padding: 0;
      }
      @media (min-width: 1200px) {
        margin: 0 auto;
        padding: 0;
        max-width: 1200px;
      }*/
      > .col-xs-12 {
        padding: 0;

        @media (--lg-viewport) {
          max-width: 600px;
          margin: 0 0 0 auto;
          padding: 0;
        }
        &+ .col-sm-6 {
          margin: 0;
          padding-right: 0;
          @media (min-width: 1200px) {
/*            max-width: 100%;*/
          }
        }
      }
    }

  }
  .environment-fluorocarbon {
    position: relative;
    background-color: #ebf5f9;
    overflow: hidden;
    
    .environment-img {
      top: 0;
      left: 0;
      height: 100%;
      @media (--sm-only-viewport) {
        left: -20%;
      }
      @media (--xs-viewport) {
        display: none;
      }
    }
    
    .row {
      > .col-sm-6 {
        padding-left: 0;
        &+ .col-sm-6 {
          @media (--lg-viewport) {
            max-width: 600px;
          }
        }
      }
    }
    .environment-title, 
    .environment-lead {
      @media (--sm-only-viewport) {
        text-shadow: 0 0 5px #fff;
      }
    }
    .environment-text {
      text-shadow: 1px 1px 0px rgba(255,255,255,0.3), 1px 1px 5px rgba(255,255,255,0.3);
      
    }
  }

}
