html.page-company-index {
  main {
    margin-bottom: 60px;
  }
  .row {
    position: relative;
    z-index: 1;
    
    @media (--sm-viewport) {
      padding: 0;
    }
  }
  .company-title {

  }
  .company-lead {
    font-size: 23px;
    font-weight: bold;
    margin: 15px 0;
    @media (--xssm-only-viewport) {

    }
    @media (--sm-viewport) {
      font-size: 28px;
    }
    @media (--md-viewport) {
      font-size: 33px;
    }
  }
  .company-text {
    color: #2788c4;
    margin: 15px 0 25px 0;
    @media (--xssm-only-viewport) {
    }
  }
  
  .company-img {
    position: absolute;
    margin: 0;
    padding: 0;
    height: 100%;
    
    img {
      height: 100%;
    }
    
    &.img-left {
      
      @media (--sm-only-viewport) {
        left: -20%;
      }
      @media (--xs-viewport) {
        display: none;
      }
    }
  }
  
  .company-spimg {
    display: none;
    @media (--xs-viewport) {
      display: block;
      img {
        width: 100%;
      }
    }
  }
  
  .company-btn {
    @media (--xssm-only-viewport) {

    }
    @media (--sm-viewport) {
      max-width: 300px;
      padding-bottom: 20px;
    }
    a {
      span {
        font-size: 20px;
      }
    }
  }

  .company-greeting {
    overflow: hidden;
    
    .row {
      @media (--md-viewport) {
        margin: 0 5%;
        padding: 0;
      }
      @media (--lg-viewport) {
        margin: 0 10%;
        padding: 0;
      }
    }
    .company-title {
      @media (--sm-only-viewport) {
      }
    }
    .company-img {

    }
    .company-spimg {
      @media (--xs-viewport) {
        display: block;
        img {
          width: 100%;
        }
      }
    }
  }
  .company-summary {
    position: relative;
    background-color: #ebf5f9;
    .row {
      > .col-sm-6 {
          padding-left: 0;
          &+ .col-sm-6 {
            @media (--lg-viewport) {
              max-width: 600px;
            }
          }
      }
    }
  }
}
