html.page-recruit-index {

  main {
    margin-bottom: 60px;
  }
  .row {
    overflow:hidden;
    position: relative;
    z-index: 1;
    
    @media (--sm-viewport) {
      padding: 0;
    }
  }
  .recruit-title {
    color: #fff;

    .en {
      &:before {
        background: url(/assets/images/recruit/index_fig_01.png);
      }
    }
  }
  .recruit-lead {
    font-size: 23px;
    font-weight: bold;
    margin: 15px 0;

    @media (--sm-viewport) {
      font-size: 28px;
    }
    @media (--md-viewport) {
      font-size: 33px;
      max-width: 82.5%;
    }
  }
  .recruit-text {
    margin: 15px 0 25px 0;
    @media (--md-viewport) {
      max-width: 75%;
    }
  }
  
  .recruit-img {
    position: absolute;
    margin: 0;
    padding: 0;
    height: 100%;
    
    img {
      height: 100%;
    }
    
    &.img-right {
      left: auto;
      right: 0;
      text-align: right;
      
      @media (--sm-only-viewport) {
        right: -20%;
      }
      
      @media (--xs-lg-viewport) {
        left: 45%;
        right: auto;
      }
    }
    
    @media (--xs-viewport) {
      display: none;
    }
  }
  
  .recruit-spimg {
    @media (--sm-viewport) {
      display: none;
    }
    
    img {
      height: auto;
      width: 100%;
    }
  }

  .recruit-btn {
    @media (--sm-viewport) {
      max-width: 300px;
      padding-bottom: 15px;
    }
    .un-button.un-button-lblue, 
    .un-button.un-button-red,
    .un-button.un-button-green {
      border-color: #fff;
    }
    .un-button.un-button-lblue {
      background-color: #5a8fdd;
      color: #fff;
    }
    .un-button.un-button-lblue:before {
      background-color: #72a4ed;
      color: #fff;
    }
    .un-button.un-button-red {
      background-color: #d65962;
      color: #fff;
    }
    .un-button.un-button-red:before {
      background-color: #e96e77;
      color: #fff;
    }
    .un-button.un-button-green {
      background-color: #56d9d7;
      color: #fff;
    }
    .un-button.un-button-green:before {
      background-color: #6bedeb;
      color: #fff;
    }
    a {
      span {
        font-size: 20px;
      }
    }
  }

  .recruit-message {
    background: linear-gradient(90deg, #5a8fdd 80%, transparent 0);
    overflow: hidden;
    position: relative;
    
    @media (--xs-viewport) {
      background: linear-gradient(135deg, #5a8fdd 40%, transparent 0)
    }
    
    .row {
      
      @media (--md-viewport) {
        margin: 0;
        padding: 0;
      }
/*      @media (--lg-viewport) {
        margin: 0 0 0 auto;
        padding: 0;
      }
      @media (min-width: 1200px) {
        margin: 0 auto;
        padding: 0;
        max-width: 1200px;
      }*/
      > .col-xs-12 {
        padding: 0;

        @media (--lg-viewport) {
          max-width: 600px;
          margin: 0 0 0 auto;
          padding: 0;
        }
        &+ .col-sm-6 {
          margin: 0;
          padding-right: 0;
          @media (min-width: 1200px) {
/*            max-width: 100%;*/
          }
        }
      }
    }
    .recruit-title {
      @media (--sm-only-viewport) {
        padding-left: 5%;
      }
    }
    .recruit-lead {
      color: #00204e;
    }
    
    .recruit-img {
      &.img-right {
      }
    }


  }
  .recruit-member {
    background: linear-gradient(90deg, transparent 40%, #d65962 0);
    overflow: hidden;
    position: relative;
    @media (--xs-viewport) {
      background: linear-gradient(135deg, #d65962 40%, transparent 0)
    }
  
    .row {
      > .col-sm-6 {
        padding: 20px 0;
        background-color: #fff;
        @media (--sm-viewport) {
          background: #fff url(/assets/images/recruit/index_bg_02.png) no-repeat right top;
          background-size: cover;
        }
        &+ .col-sm-6 {
          background-color: transparent;

          @media (--sm-viewport) {
            background-image: none;
          }
          @media (--lg-viewport) {
            max-width: 600px;
          }
        }
        .recruit-img {
          text-align: center;
          position: relative;
          height: auto;
          @media (--sm-only-viewport) {
            padding-top: 15%;
          }
          img {
            width: 100%;
            max-width: 100%;
            @media (--sm-only-viewport) {
              width: 90%;
            }
            @media (--md-viewport) {
              height: 100%;
              width: auto;
            }
          }

/*          &:before {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            height: 100%;
            width: 100px;
            background-color: #d65962;
            tranform: rotate(15deg);
          }*/
        }
      }
    }
/*    .recruit-title, 
    .recruit-lead {
      @media (--sm-only-viewport) {
        text-shadow: 0 0 5px #fff;
      }
    }*/
    .recruit-lead {
      color: #4c0a0f;
    }
    .recruit-text {
    }
    .recruit-img {
      position: relative;
      top: auto;
      left: auto;


    }
  }
  .recruit-workplace {
    background: linear-gradient(90deg, #56d9d7 80%, transparent 0);
    overflow: hidden;
    position: relative;
    @media (--xs-viewport) {
      background: linear-gradient(135deg, #56d9d7 40%, transparent 0)
    }
    
    .row {
      @media (--md-viewport) {
        margin: 0;
        padding: 0;
      }
/*      @media (--lg-viewport) {
        margin: 0 0 0 auto;
        padding: 0;
      }
      @media (min-width: 1200px) {
        margin: 0 auto;
        padding: 0;
        max-width: 1200px;
      }*/
      > .col-xs-12 {
        padding: 0;

        @media (--lg-viewport) {
          max-width: 600px;
          margin: 0 0 0 auto;
          padding: 0;
        }
        &+ .col-sm-6 {
          margin: 0;
          padding-right: 0;
          @media (min-width: 1200px) {
/*            max-width: 100%;*/
          }
        }
      }
    }
    .recruit-title {
      @media (--sm-only-viewport) {
        padding-left: 5%;
      }
    }
    .recruit-lead {
      color: #00403f;
    }
    .recruit-img {
      &.img-right {
      }
    }
  }

  .recruit-faq {
    margin: 0;
    padding: 0;
    background-color: #656565;
    padding: 40px 5% 20px 5%;
    @media (--sm-viewport) {
      padding: 40px 5%;
    }
    @media (--xs-viewport) {
      padding: 0 5% 20px 5%;
    }
    .section-faq {
      @media (--md-viewport) {
        max-width: 90%;
        margin: 0 auto;
      }
      @media (--lg-viewport) {
        max-width: 1080px;
      }
      h2 {
        padding-top: 0;
        &:before {
          content: "";
        }
      }
      .recruit-btn {
        margin: 20px auto 0 auto;
        padding-bottom: 0;
        .un-button-gray {
          color: #fff;
          background-color: #656565;
          border-color: #fff;
          &:before {
            background-color: #767676;
          }
        }
      }
    }
  }

  .recruit-entry {
    background: #eaf4f9 url(/assets/images/recruit/entry_ph.jpg) no-repeat center center;
    background-size: cover;
    margin-top: 0;
    div.recruit__title {
      &.triangle {
/*        .triangle__title {
          color: #2788c4;
        }*/
        
        &:before {
          border-color: #2788c4 transparent transparent transparent;
        }
      }
    }
    .entry-btn {
      max-width: 1080px;
      @media (--lg-viewport) {
        margin: 0 auto;
      }
      &.row {
      h3 {
        position: relative;
        max-width: 520px;
        margin: 30px auto 20px auto;
        padding: 0;
        font-size: 30px;
        color: #2788c4;
        text-align: center;
        &:before, &:after {
          position: absolute;
          border-bottom: 2px solid #2788c4;
          top: 50%;
          content: "";
          width: calc(50%-4rem);
        }
        &:before {
          left: 0;
        }
        &:after {
          right: 0;
        }
      }
      .row {
        margin: 0;
        padding: 0 0 30px 0;
        li {
          @media (--sm-viewport) {
            padding: 0 5px 30px 5px;
          }
          list-style-type: none;
          &:first-of-type {
            .recruit-btn {
              @media (--sm-viewport) {
                margin: 0 5px 0 auto;
              }
            }
          }
          &:last-of-type {
            .recruit-btn {
              @media (--sm-viewport) {
                margin: 0 auto 0 5px;
              }
            }
          }
          .un-button span {
            font-size: 18px;
          }
        }
      }
      }
      
      .caution {
        margin: 0;
        font-size: 14px;
      }
    }
  }

}

@media (--xs-viewport) {
}
@media (--xssm-only-viewport) {
}
@media (--sm-viewport) {
}
@media (--sm-only-viewport) {
}
@media (--md-viewport) {
}
@media (--lg-viewport) {
}
